import * as React from "react"
import Loadable from "react-loadable";
import LinearProgress from '@material-ui/core/LinearProgress';

import "../styles/App.scss";

const loader = () => <div><LinearProgress /></div>;
//
const HomeLazy = Loadable({
  loader: () => import("../containers/Main"),
  loading: loader,
});

const Index = () => {
  return <HomeLazy />;
};

export default Index
